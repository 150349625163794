import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  nextQuestionAction,
  questionResultAction,
  previousQuestionAction,
  reportDTOAction,
  getBmiQuestionsAction,
  bmiCalculationAction,
} from "../../redux/actions/assessmentActions";
import {
  NEXT_QUESTION_RESET,
  PREVIOUS_QUESTION_RESET,
  QUESTION_RESULT_RESET,
} from "../../redux/constants/assessmentConstants";
import "./SelfAssessment.css";

import Assessment from "./Assessment";
import { addSignedConsentFormsAction, getConsentFormByPatientAction, logout, updateUserDetailsFormsAction } from "../../redux/actions/userActions";
import Content from "../../components/Content";
import axios from "axios";
import { BASE_URL_LOGIN } from "../../redux/actions/ip";
import ConsentFormModal from "./ConsentFormModal/ConsentFormModal";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import { getMonth, getYear } from "date-fns";
import { getDayDiff, validateOnlyChacters } from "../../utils/validators";
import range from "lodash/range";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

const Questionare = ({ history }) => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const activeToken = useSelector((state) => state.activeToken);

  const nextQuestion = useSelector((state) => state.nextQuestion);
  const questionResult = useSelector((state) => state.questionResult);
  const previousQuestion = useSelector((state) => state.previousQuestion);
  const getbmiquestions = useSelector((state) => state.getbmiquestions);

  const [nextQuestionData, setNextQuestionData] = useState("");
  const [questionResultData, setQuestionResultData] = useState("");
  const [questionId, setQuestionId] = useState("");
  const [noInputError, setNoInputError] = useState("");
  const [allQuestionData, setAllQuestionData] = useState("");
  const [bmiDemographic, setBmiDemographic] = useState("");
  const [benzQuestion, setBenzQuestion] = useState("");

  const [yesToggle, setYesToggle] = useState(false);
  const [NoToggle, setNoToggle] = useState(false);

  const [multipleAnswer, setMultipleAnswer] = useState([]);
  const [singleAnswer, setSingleAnswer] = useState([]);
  const [selected, setSelected] = useState([]);

  const [pounds, setPounds] = useState("");
  const [feet, setFeet] = useState("");
  const [inches, setInches] = useState("");

  const [userDetails, setUserDetails] = useState({});
  const [phoneNumber, setPhoneNumber] = useState("");
  const [iscellincluded, setIsCellIncluded] = useState(false);
  const [requestData, setRequestData] = useState({
    phoneNumber: "",
    iscellincluded: false,
  });

  const [requestDataError, setRequestDataError] = useState({
    phoneNumberError: "",
    iscellincludedError: "",
  });

  const [timerState, setTimerState] = useState({
    isRunning: false,
    mm: 0,
    ss: 0,
    ms: 0
  });

  const [timerID, setTimerID] = useState(0);

  const [consentForm, setConsentForm] = useState({
    data: [],
    loading: false,
    error: "",
  });


  const [consentFormModal, setConsentFormModal] = React.useState({
    status: false,
    step: 1,
  });

  const [birthDateError, setBirthDateError] = React.useState(false);

  const [formData, setformData] = React.useState({
    fName: "",
    lName: "",
    birthDate: "",
    ethnicity: ""
  });

  const [formDataError, setformDataError] = React.useState({
    fName: "",
    lName: "",
    birthDate: "",
    ethnicity: ""
  });

  const [focus, setFocus] = React.useState(true);
  const [startDate, setStartDate] = React.useState(null);
  const [component, setComponent] = React.useState(null);
  const years = range(1920, getYear(new Date()) + 1, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];


  const [mapAreas, setMapAreas] = useState({
    name: "body-map",
    areas: [
      { id: 1, name: "Rt Jaw", shape: "circle", coords: [231, 82, 10], preFillColor: "#c0c0c2", top: 84, left: 160, value: 0 },
      { id: 2, name: "Rt Chest/Breast", shape: "circle", coords: [201, 174, 10], preFillColor: "#c0c0c2", top: 155, left: 137, value: 0 },
      { id: 3, name: "Lt Chest/Breast", shape: "circle", coords: [244, 173, 10], preFillColor: "#c0c0c2", top: 156, left: 170, value: 0 },
      { id: 4, name: "Rt Upper Arm", shape: "circle", coords: [147, 196, 10], preFillColor: "#c0c0c2", top: 172, left: 94, value: 0 },
      { id: 5, name: "Lt Upper Arm", shape: "circle", coords: [277, 195, 10], preFillColor: "#c0c0c2", top: 172, left: 198, value: 0 },
      { id: 6, name: "Rt Elbow", shape: "circle", coords: [142, 256, 10], preFillColor: "#c0c0c2", top: 218, left: 90, value: 0 },
      { id: 7, name: "Lt Elbow", shape: "circle", coords: [269, 256, 10], preFillColor: "#c0c0c2", top: 218, left: 190, value: 0 },
      { id: 8, name: "Rt Lower Arm", shape: "circle", coords: [145, 289, 10], preFillColor: "#c0c0c2", top: 244, left: 94, value: 0 },
      { id: 9, name: "Lt Lower Arm", shape: "circle", coords: [276, 288, 10], preFillColor: "#c0c0c2", top: 243, left: 194, value: 0 },
      { id: 10, name: "Abdomen", shape: "circle", coords: [223, 278, 10], preFillColor: "#c0c0c2", top: 239, left: 155, value: 0 },
      { id: 11, name: "Pelvis", shape: "circle", coords: [229, 317, 10], preFillColor: "#c0c0c2", top: 267, left: 158, value: 0 },
      { id: 12, name: "Rt Groin", shape: "circle", coords: [200, 335, 10], preFillColor: "#c0c0c2", top: 282, left: 136, value: 0 },
      { id: 13, name: "Lt Groin", shape: "circle", coords: [260, 335, 10], preFillColor: "#c0c0c2", top: 282, left: 182, value: 0 },
      { id: 14, name: "Rt Wrist/Hand", shape: "circle", coords: [143, 357, 10], preFillColor: "#c0c0c2", top: 298, left: 94, value: 0 },
      { id: 15, name: "Lt Wrist/Hand", shape: "circle", coords: [293, 358, 10], preFillColor: "#c0c0c2", top: 296, left: 208, value: 0 },
      { id: 16, name: "Rt Upper Leg", shape: "circle", coords: [196, 413, 10], preFillColor: "#c0c0c2", top: 341, left: 133, value: 0 },
      { id: 17, name: "Lt Upper Leg", shape: "circle", coords: [273, 412, 10], preFillColor: "#c0c0c2", top: 341, left: 195, value: 0 },
      { id: 18, name: "Rt Knee", shape: "circle", coords: [186, 479, 10], preFillColor: "#c0c0c2", top: 392, left: 124, value: 0 },
      { id: 19, name: "Lt Knee", shape: "circle", coords: [283, 478, 10], preFillColor: "#c0c0c2", top: 392, left: 201, value: 0 },
      { id: 20, name: "Rt Lower Leg", shape: "circle", coords: [173, 520, 10], preFillColor: "#c0c0c2", top: 424, left: 115, value: 0 },
      { id: 21, name: "Lt Lower Leg", shape: "circle", coords: [282, 521, 10], preFillColor: "#c0c0c2", top: 426, left: 201, value: 0 },
      { id: 22, name: "Rt Ankle/Foot", shape: "circle", coords: [164, 660, 10], preFillColor: "#c0c0c2", top: 534, left: 107, value: 0 },
      { id: 23, name: "Lt Ankle/Foot", shape: "circle", coords: [278, 660, 10], preFillColor: "#c0c0c2", top: 534, left: 196, value: 0 },
      { id: 24, name: "Head", shape: "circle", coords: [592, 39, 10], preFillColor: "#c0c0c2", top: 51, left: 134, value: 0 },
      { id: 25, name: "Lt Jaw", shape: "circle", coords: [556, 82, 10], preFillColor: "#c0c0c2", top: 82, left: 108, value: 0 },
      { id: 26, name: "Neck", shape: "circle", coords: [587, 100, 10], preFillColor: "#c0c0c2", top: 96, left: 131, value: 0 },
      { id: 27, name: "Lt Shoulder", shape: "circle", coords: [539, 134, 10], preFillColor: "#c0c0c2", top: 123, left: 95, value: 0 },
      { id: 28, name: "Rt Shoulder", shape: "circle", coords: [633, 133, 10], preFillColor: "#c0c0c2", top: 123, left: 166, value: 0 },
      { id: 29, name: "Upper Back", shape: "circle", coords: [593, 178, 10], preFillColor: "#c0c0c2", top: 155, left: 137, value: 0 },
      { id: 30, name: "Lower Back", shape: "circle", coords: [586, 272, 10], preFillColor: "#c0c0c2", top: 228, left: 132, value: 0 },
      { id: 31, name: "Lt Hip", shape: "circle", coords: [556, 297, 10], preFillColor: "#c0c0c2", top: 250, left: 107, value: 0 },
      { id: 32, name: "Rt Hip", shape: "circle", coords: [627, 296, 10], preFillColor: "#c0c0c2", top: 250, left: 161, value: 0 },
      { id: 33, name: "Lt Buttocks", shape: "circle", coords: [550, 332, 10], preFillColor: "#c0c0c2", top: 276, left: 102, value: 0 },
      { id: 34, name: "Rt Buttocks", shape: "circle", coords: [621, 332, 10], preFillColor: "#c0c0c2", top: 276, left: 156, value: 0 },
    ]
  });


  useEffect(
    () => {
      if (!activeToken) {
        history.push('/login');
      } else if (!activeToken.token) {
        history.push('/login');
      } else if (
        userLogin &&
        userLogin.userInfo &&
        userLogin.userInfo.applicationUser &&
        userLogin.userInfo.applicationUser.isPatient === true &&
        userLogin.userInfo.applicationUser.isSuperAdmin === false
      ) {
        history.push('/questionaires');
      }
    },
    [activeToken, userLogin, history]
  );

  useEffect(() => {
    const userInfo = localStorage.getItem('userInfo');
    const tokenInfo = localStorage.getItem('token');
    const clientIdInfo = localStorage.getItem('clientId');
    const getMenusInfo = localStorage.getItem('getMenus');

    if (userInfo === "undefined" || userInfo === undefined) {
      localStorage.removeItem('userInfo');
      localStorage.removeItem('userInfo');
      localStorage.removeItem('token');
      localStorage.removeItem('clientId');
      localStorage.removeItem('clientUrl');
      localStorage.removeItem('getMenus');
      history.push("/login");
      dispatch(logout())
    } else if (tokenInfo === "undefined" || tokenInfo === undefined) {
      localStorage.removeItem('userInfo');
      localStorage.removeItem('userInfo');
      localStorage.removeItem('token');
      localStorage.removeItem('clientId');
      localStorage.removeItem('clientUrl');
      localStorage.removeItem('getMenus');
      history.push("/login");
      dispatch(logout())
    } else if (clientIdInfo === "undefined" || clientIdInfo === undefined) {
      localStorage.removeItem('userInfo');
      localStorage.removeItem('userInfo');
      localStorage.removeItem('token');
      localStorage.removeItem('clientId');
      localStorage.removeItem('clientUrl');
      localStorage.removeItem('getMenus');
      history.push("/login");
      dispatch(logout())
    } else if (getMenusInfo === "undefined" || getMenusInfo === undefined) {
      localStorage.removeItem('userInfo');
      localStorage.removeItem('token');
      localStorage.removeItem('clientId');
      localStorage.removeItem('clientUrl');
      localStorage.removeItem('getMenus');
      history.push("/login");
      dispatch(logout())
    }
  }, [dispatch])


  useEffect(() => {
    if (userLogin && userLogin.userInfo && userLogin.userInfo.token) {
      dispatch(nextQuestionAction());
      getUserDetails();
    } else {
      history.push("/login");
    }
  }, [userLogin, dispatch, history]);

  const getUserDetails = () => {
    axios
      .get(`${userLogin.userInfo.apiurl}/auth/support/getuserdetails`, {
        headers: {
          Authorization: `Bearer ${userLogin.userInfo.token}`,
          "custom-clinicid-header": userLogin.userInfo.clinicid,
        },
      })
      .then((res) => {
        let userData = res?.data;
        setUserDetails(userData);
        setformData({
          fName: userData.firstName,
          lName: userData.lastName,
          birthDate: userData.birthDate,
          ethnicity: userData.ethnicity
        });
        setStartDate(new Date(userData.birthDate));
        getConsentForms(res.data);
      })
      .catch((err) => console.log(err));
  };

  //nextQuestion
  useEffect(() => {
    if (nextQuestion && nextQuestion.userInfo) {
      setSingleAnswer([]);
      setNextQuestionData(nextQuestion.userInfo);

      setQuestionResultData("");

      if (nextQuestion.userInfo.sessionTimer) {
        let timeArr = nextQuestion.userInfo.sessionTimer.split(':');
        let orArr = timerState;
        orArr.mm = Number(timeArr[0]);
        orArr.ss = Number(timeArr[1]);
        orArr.ms = 0;
        setTimerState(orArr);
        clickHandler();
      } else {
        clickHandler();
      }

      if (
        nextQuestion.userInfo.answerType === "MultiSelect" ||
        nextQuestion.userInfo.answerType === "SingleSelect"
      ) {
        setMultipleAnswer(
          nextQuestion.userInfo.answers
            .filter((item) => item.isSelected)
            .map((item) => item.answerOptionId)
        );
      } else if (nextQuestion.userInfo.answerType === "FreeText") {
        setMultipleAnswer([nextQuestion.userInfo.answerText]);
      } else {
        setMultipleAnswer([]);
      }

      setYesToggle(false);
      setNoToggle(false);
    }
  }, [nextQuestion]);

  //QuestionResult
  useEffect(() => {
    if (
      questionResult &&
      questionResult.userInfo &&
      questionResult.loading === false
    ) {
      dispatch({ type: NEXT_QUESTION_RESET });
      dispatch({ type: PREVIOUS_QUESTION_RESET });

      setQuestionResultData(questionResult.userInfo);
      setMultipleAnswer("");
      setNextQuestionData("");
      setSingleAnswer([]);
      setYesToggle(false);
      setNoToggle(false);
      setNoInputError("");

      if (
        questionResult.userInfo.answerType === "MultiSelect" ||
        questionResult.userInfo.answerType === "SingleSelect"
      ) {
        setMultipleAnswer(
          questionResult.userInfo.answers
            .filter((item) => item.isSelected)
            .map((item) => item.answerOptionId)
        );
      } else if (
        questionResult.userInfo.answerType === "YesNo" &&
        questionResult.userInfo.answerText !== null
      ) {
        questionResult.userInfo.answerText === "yes"
          ? setYesToggle(true)
          : setNoToggle(true);
        setMultipleAnswer([{ answer: questionResult.userInfo.answerText }]);
      } else if (questionResult.userInfo.answerType === "FreeText") {
        setMultipleAnswer([questionResult.userInfo.answerText]);
      }
    } else if (
      questionResult &&
      questionResult.message === "Assessment complete."
    ) {
      setPounds("");
      setInches("");
      setFeet("");
      dispatch(logout());
    }
  }, [questionResult, dispatch]);

  //PreviousResult
  useEffect(() => {
    if (
      previousQuestion &&
      previousQuestion.userInfo &&
      previousQuestion.loading === false
    ) {
      dispatch({ type: QUESTION_RESULT_RESET });
      dispatch({ type: NEXT_QUESTION_RESET });
      setSingleAnswer([]);
      setYesToggle(false);
      setNoToggle(false);
      setQuestionId(previousQuestion.userInfo.questionId);

      if (
        previousQuestion.userInfo.answerType === "MultiSelect" ||
        previousQuestion.userInfo.answerType === "SingleSelect"
      ) {
        setMultipleAnswer(
          previousQuestion.userInfo.answers
            .filter((item) => item.isSelected)
            .map((item) => item.answerOptionId)
        );
      } else if (previousQuestion.userInfo.answerType === "FreeText") {
        setMultipleAnswer([previousQuestion.userInfo.answerText]);
      } else if (previousQuestion.userInfo.answerText === "yes") {
        setYesToggle(true);
        setMultipleAnswer([{ answer: "yes" }]);
      } else if (previousQuestion.userInfo.answerText === "no") {
        setNoToggle(true);
        setMultipleAnswer([{ answer: "no" }]);
      }
      setNextQuestionData("");
      setQuestionResultData("");
    }
  }, [previousQuestion, dispatch]);

  useEffect(() => {
    if (
      allQuestionData.answerType === "SingleSelect" &&
      singleAnswer.length > 0
    ) {
      onNextQuestion();
    } else if (
      allQuestionData.answerType === "YesNo" &&
      singleAnswer.length > 0
    ) {
      onNextQuestion();
    }
    //eslint-disable-next-line
  }, [singleAnswer, allQuestionData]);

  //BMIQuestions
  useEffect(() => {
    if ((nextQuestionData.name || questionResultData.name) === "Anon_Basic_3") {
      dispatch(getBmiQuestionsAction());
    }
  }, [nextQuestionData, questionResultData, dispatch]);

  //BMIQuestions
  useEffect(() => {
    if (previousQuestion &&
      previousQuestion.userInfo &&
      previousQuestion.userInfo.name === "Anon_Basic_3") {
      dispatch(getBmiQuestionsAction());
    }
  }, [previousQuestion, dispatch]);

  //GET BMIQuestions
  useEffect(() => {
    if (
      getbmiquestions.userInfo &&
      getbmiquestions.userInfo.questions &&
      getbmiquestions.userInfo.questions.length > 0
    ) {
      getbmiquestions.userInfo.questions.filter((item) => item.questiontext);
      getbmiquestions.userInfo.questions.map((arr) => {
        if (arr.questiontext === "pounds" && arr.answertext) {
          setPounds(arr.answertext);
        } else if (arr.questiontext === "inches" && arr.answertext) {
          setInches(arr.answertext);
        } else if (arr.questiontext === "feet" && arr.answertext) {
          setFeet(arr.answertext);
        }
      });
    }
  }, [getbmiquestions]);

  //All question Data
  useEffect(() => {
    if (questionResult && questionResult.userInfo) {
      setAllQuestionData(questionResult.userInfo);
      setQuestionId(allQuestionData.questionId);
    } else if (previousQuestion && previousQuestion.userInfo) {
      setAllQuestionData(previousQuestion.userInfo);
    } else if (nextQuestion && nextQuestion.userInfo) {
      setAllQuestionData(nextQuestion.userInfo);
      setQuestionId(allQuestionData.questionId);
    }
  }, [questionResult, previousQuestion, nextQuestion, allQuestionData]);

  const onNextQuestion = async () => {
    setBenzQuestion("");
    dispatch({ type: QUESTION_RESULT_RESET });
    dispatch({ type: PREVIOUS_QUESTION_RESET });
    setNoInputError("");
    let formData;
    const answerJson = [];
    if (
      allQuestionData.answerType === "Note" ||
      allQuestionData.answerType === "NoteFinal"
    ) {
      formData = {
        questionId: allQuestionData.questionId,
        answers: [],
        birthDate: allQuestionData.birthDate,
        deviceType: "web",
        sessionTimer: format(timerState.mm) + ":" + format(timerState.ss)// + ":" + format(timerState.ss)
      };

      dispatch(questionResultAction(formData));
      const areas = await mapAreas.areas.map(item =>
        item.id && { ...item, value: 0, prefill: "#c0c0c2" }
      );
      setMapAreas({
        name: mapAreas.name,
        areas
      });
    } else if (multipleAnswer.length > 0) {
      if (
        userDetails.phoneNumber == null &&
        allQuestionData.name === "Anon_StartNote"
      ) {
        if (
          !requestData.phoneNumber.trim() ||
          requestData.phoneNumber.trim() === ""
        ) {
          setRequestDataError({
            ...requestDataError,
            phoneNumberError: "Please enter a phone number",
          });
        } else {
          const config = {
            headers: {
              "custom-clinicid-header": userLogin.userInfo.clinicid,
            },
          };
          axios.post(
            `${BASE_URL_LOGIN}/auth/support/updateuserdetails`,
            requestData,
            config
          );
          multipleAnswer &&
            multipleAnswer.length > 0 &&
            multipleAnswer.map((answer) => answerJson.push({ answer }));
          formData =
            allQuestionData && allQuestionData.answerType === "YesNo"
              ? {
                questionId: allQuestionData.questionId,
                answers: multipleAnswer,
                birthDate: allQuestionData.birthDate,
                deviceType: "web",
                sessionTimer: format(timerState.mm) + ":" + format(timerState.ss) //+ ":" + format(timerState.ss)
              }
              : {
                questionId: allQuestionData.questionId,
                answers: answerJson,
                birthDate: allQuestionData.birthDate,
                deviceType: "web",
                sessionTimer: format(timerState.mm) + ":" + format(timerState.ss) //+ ":" + format(timerState.ss)
              };

          dispatch(questionResultAction(formData));
        }
      } else if (allQuestionData.name == "Q_7_1") {
        multipleAnswer &&
          multipleAnswer.length > 0 &&
          allQuestionData.answers.map((answers, i) => {
            if (answers.isSelected) {
              answerJson.push({ answer: answers.answerOptionId, score: answers.score ? answers.score : 0 });
            }
          });
        formData = {
          questionId: allQuestionData.questionId,
          answers: answerJson,
          birthDate: allQuestionData.birthDate,
          deviceType: "web",
          sessionTimer: format(timerState.mm) + ":" + format(timerState.ss) //+ ":" + format(timerState.ss)
        };

        dispatch(questionResultAction(formData));
        const areas = await mapAreas.areas.map(item =>
          item.id && { ...item, value: 0, prefill: "#c0c0c2" }
        );
        setMapAreas({
          name: mapAreas.name,
          areas
        });

      } else {
        multipleAnswer &&
          multipleAnswer.length > 0 &&
          multipleAnswer.map((answer) => answerJson.push({ answer }));
        formData =
          allQuestionData && allQuestionData.answerType === "YesNo"
            ? {
              questionId: allQuestionData.questionId,
              answers: multipleAnswer,
              birthDate: allQuestionData.birthDate,
              deviceType: "web",
              sessionTimer: format(timerState.mm) + ":" + format(timerState.ss) //+ ":" + format(timerState.ss)
            }
            : {
              questionId: allQuestionData.questionId,
              answers: answerJson,
              birthDate: allQuestionData.birthDate,
              deviceType: "web",
              sessionTimer: format(timerState.mm) + ":" + format(timerState.ss) //+ ":" + format(timerState.ss)
            };

        dispatch(questionResultAction(formData));
      }
    } else if (allQuestionData.name === "Anon_Basic_3") {
      if (!pounds || pounds == "0" || pounds == 0) {
        setNoInputError("Please select your weight!");
      } else if (!feet || feet == "0" || feet == 0) {
        setNoInputError("Please provide your feet!");
      } else if (!inches || inches == "") {
        setNoInputError("Please provide your inches!");
      } else if (pounds.length > 0 && feet.length > 0) {
        const questions = [];
        if (getbmiquestions &&
          getbmiquestions.userInfo.questions &&
          getbmiquestions.userInfo.questions.length > 0) {
          getbmiquestions.userInfo.questions.map((arr) => {
            if (arr.questiontext === "pounds") {
              questions.push({
                questionid: arr.questionid,
                questiontext: arr.questiontext,
                questionname: arr.questionname,
                assessmentresid: arr.assessmentresid,
                answertext: pounds.toString(),
              })
            } else if (arr.questiontext === "inches") {
              questions.push({
                questionid: arr.questionid,
                questiontext: arr.questiontext,
                questionname: arr.questionname,
                assessmentresid: arr.assessmentresid,
                answertext: inches.toString(),
              })
            } else if (arr.questiontext === "feet") {
              questions.push({
                questionid: arr.questionid,
                questiontext: arr.questiontext,
                questionname: arr.questionname,
                assessmentresid: arr.assessmentresid,
                answertext: feet.toString(),
              })
            }
          });
        }

        let bmiData = await dispatch(bmiCalculationAction(questions));
        formData = {
          questionId: allQuestionData.questionId,
          answers: [{ answer: "0" }],
          birthDate: allQuestionData.birthDate,
          deviceType: "web",
          sessionTimer: format(timerState.mm) + ":" + format(timerState.ss) //+ ":" + format(timerState.ss)
        };
        dispatch(questionResultAction(formData));
      } else {
        setNoInputError("Please answer the question");
      }
    } else if (allQuestionData.name === "Anon_Basic_4") {
      if (bmiDemographic !== "") {
        formData = {
          questionId: allQuestionData.questionId,
          Answers: [{ answer: bmiDemographic }],
          deviceType: "web",
          sessionTimer: format(timerState.mm) + ":" + format(timerState.ss) //+ ":" + format(timerState.ss)
        };

        dispatch(questionResultAction(formData));
      } else {
        setNoInputError("Please answer the question");
      }
    } else {
      setNoInputError("Please answer the question");
    }
  };

  const onPreviousQuestion = () => {
    //send currect QuestionID
    setNoInputError("");
    var currentquestionid = questionId;
    dispatch(previousQuestionAction(currentquestionid));
  };

  const onReportDTO = () => {
    var assessmentResultId = allQuestionData.assessmentResultId;
    var isadmin = true;
    var isprintpdf = false;
    dispatch(reportDTOAction({ assessmentResultId, isadmin, isprintpdf }));
  };

  const onFinalSubmit = () => {
    //send currect QuestionID

    // var currentquestionid = questionId;
    // dispatch(questionResultAction(currentquestionid));

    dispatch(logout());
  };

  useEffect(() => {
    return () => {
      dispatch({ type: NEXT_QUESTION_RESET });
      dispatch({ type: PREVIOUS_QUESTION_RESET });
      dispatch({ type: QUESTION_RESULT_RESET });
    };
  }, [dispatch]);

  const clickHandler = () => {
    let { isRunning } = timerState;
    if (isRunning) {
      // Running => Stop
      clearInterval(timerID);
    } else {
      // Stop => Running
      let { mm, ss, ms } = timerState;
      let timerIDTmp = setInterval(() => {
        ms++;
        if (ms >= 100) {
          ss++;
          ms = 0;
        }
        if (ss >= 60) {
          mm++;
          ss = 0;
        }
        setTimerState({ ...timerState, mm: mm, ss: ss, ms: ms });
      }, 10);
      setTimerID(timerIDTmp);
    }
    setTimerState({ ...timerState, isRunning: !isRunning });
  }

  const format = (num) => {
    return (num + '').length === 1 ? '0' + num : num + '';
  }


  const getConsentForms = async (user_details) => {
    if (user_details?.id) {
      setConsentForm(prevData => ({ ...prevData, loading: true }));
      const data = await dispatch(getConsentFormByPatientAction(user_details.id));
      if (data && data.status && data.data && data.data.length > 0) {
        setConsentForm({ data: data.data, loading: false });
        setConsentFormModal({ status: true, step: 1 });
      } else {
        setConsentForm({ data: [], loading: false });
        setConsentFormModal({ status: false, step: 1 });
      }
    } else {
      setConsentFormModal({ status: false, step: 1 });
      setConsentForm({ data: [], loading: false });
    }
  };

  const consentStepHandler = () => {
    if (consentFormModal.step === 1 && consentForm.data && consentForm.data.length > 1) {
      setConsentFormModal(prevData => ({ ...prevData, step: prevData.step + 1 }));
    } else if (consentFormModal.step === 2) {
      if (consentForm && consentForm.data && consentForm.data.length > 2) {
        setConsentFormModal(prevData => ({
          ...prevData,
          step: prevData.step + 1,
        }));
      } else {
        submitConsentFormHandler();
      }
    } else if (consentFormModal.step) {
      submitConsentFormHandler();
    }
  };


  const handlePrevious = () => {
    if (consentFormModal.step !== 1) {
      setConsentFormModal(prevData => ({ ...prevData, step: prevData.step - 1 }));
    }
  };

  const submitConsentFormHandler = async () => {
    let SignedConsentForms = [];
    if (consentForm &&
      consentForm.data &&
      consentForm.data.length > 0) {
      consentForm.data.map((item) => {
        SignedConsentForms.push({
          ConsentFormId: item.id,
          fileName: item.documentName,
          FormType: item.formType
        })
      });
    }
    let data = await dispatch(addSignedConsentFormsAction(SignedConsentForms));
    if (data && data.status) {
      setConsentFormModal({ status: false, step: 1 });
      setConsentForm({ data: [], loading: false });
      toast.success(data.message);
    } else {
      setConsentFormModal({ status: false, step: 1 });
      toast.error(data.message);
    }
  };


  const onFormSubmitHandler = async (e) => {
    //e.preventDefault();
    setBirthDateError(false);
    if (!formData.fName || formData.fName.trim() === "") {
      setformDataError({
        ...formDataError,
        fName: "Please enter your first name",
      });
    } else if (!validateOnlyChacters(formData.fName)) {
      setformDataError({
        ...formDataError,
        fName: "Name can only be characters",
      });
    } else if (!formData.lName || formData.lName.trim() === "") {
      setformDataError({
        ...formDataError,
        lName: "Please enter your last name",
      });
    } else if (!validateOnlyChacters(formData.lName)) {
      setformDataError({
        ...formDataError,
        lName: "Name can only be characters",
      });
    } else if (!formData.birthDate || formData.birthDate.trim() === "" || formData.birthDate.trim() === "Invalid date") {
      setformDataError({ ...formDataError, birthDate: "Please select date of birth" });
    } else if (getDayDiff(formData.birthDate) < 365) {
      setBirthDateError(true);
    } else if (!formData.ethnicity || formData.ethnicity.trim() === "") {
      setformDataError({
        ...formDataError,
        ethnicity: "Please select ethnicity",
      });
    } else {
      const formDataInput = {
        UserId: userDetails.id,
        FirstName: formData.fName,
        LastName: formData.lName,
        Ethnicity: formData.ethnicity,
        birthDate: formData.birthDate,
        websiteId: "98DF879A-A222-4B39-AFA0-FE4C38429EA8"
      };

      let data = await dispatch(updateUserDetailsFormsAction(formDataInput));
      if (data && data.status) {
        dispatch(nextQuestionAction());
        getUserDetails();
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
    }
  };

  return (
    <Content
      currentMenu="Self Assessments"
      headerTitle="Self Assessment"
      current="questionare"
    >
      <div className="selfContainer">
        {
          userDetails && userDetails.birthDate === '0001-01-01T00:00:00' ||
            !userDetails.ethnicity? 
            <div className="selfContainer patient-update-form">
              <div className="selfContainer__box">
                <p className="audioContainer__button--text1"
                  dangerouslySetInnerHTML={{ __html: 'IMPORTANT: Before proceeding, please confirm that this is your correct full legal name and birthday.' }} />

                <form >
                  <div className="patient__flex-update-form">
                    <div className='patient_form_input' style={{
                      maxWidth: '35rem'
                    }}>
                      <label>First Name</label>
                      <input type='text' placeholder='Enter First Name'
                        value={formData.fName}
                        onChange={(event) => {
                          setformData({ ...formData, fName: event.target.value });
                          setformDataError("");
                        }}
                      />
                      <p className="error_message" >
                        {formDataError.fName}
                      </p>
                    </div>
                    <div className='patient_form_input' style={{
                      maxWidth: '35rem'
                    }}>
                      <label>Last Name</label>
                      <input type='text'
                        placeholder='Enter Last Name'
                        value={formData.lName}
                        onChange={(event) => {
                          setformData({ ...formData, lName: event.target.value });
                          setformDataError("");
                        }}
                      />
                      <p className="error_message" >
                        {formDataError.lName}
                      </p>
                    </div>
                  </div>
                  <div className="patient__flex-update-form date-flex">
                    <div className="formgroup--validation">
                      <div className="__input" style={{
                        maxWidth: '35rem'
                      }}
                      >
                        <label>Select date of birth:</label>
                        <div className='patient_form_dob'>
                          <DatePicker
                            key="birthDate"
                            renderCustomHeader={({
                              date,
                              changeYear,
                              changeMonth,
                              decreaseMonth,
                              increaseMonth,
                              prevMonthButtonDisabled,
                              nextMonthButtonDisabled,
                            }) => (
                              <div
                                style={{
                                  margin: 10,
                                  display: "flex",
                                  justifyContent: "center"
                                }}
                              >
                                <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                  {"<"}
                                </button>
                                <select
                                  value={getYear(date)}
                                  onChange={({ target: { value } }) => changeYear(value)}
                                >
                                  {years.map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </select>

                                <select
                                  value={months[getMonth(date)]}
                                  onChange={({ target: { value } }) =>
                                    changeMonth(months.indexOf(value))
                                  }
                                >
                                  {months.map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </select>

                                <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                  {">"}
                                </button>
                              </div>
                            )}
                            selected={startDate}
                            dateFormat="MM-dd-yyyy"
                            ref={(r) => {
                              setComponent(r);
                            }}
                            onChange={(date) => {
                              setStartDate(date);
                              setformData({
                                ...formData,
                                birthDate: moment(date).format("YYYY-MM-DD")
                              });
                              setformDataError("");
                              if (getDayDiff(date) < 365) {
                                setBirthDateError(true);
                              } else setBirthDateError(false);
                            }}
                            className="loginContainer__formgroup--input"
                            placeholderText="Date of Birth"
                          />
                          <svg
                            className="patient_form_dob_icon"
                            onClick={() => {
                              setFocus(focus ? false : true);
                              component.setOpen(focus);
                            }}
                          >
                            <use xlinkHref="/sprite.svg#icon-calendar" />
                          </svg>
                        </div>
                      </div>
                      <p className="error_message" >
                        {formDataError.birthDate}
                      </p>
                    </div>
                  </div>

                  {
                    birthDateError &&
                    <div className="patient__flex-form date-flex">
                      <p
                        className="error_message"
                        style={{ width: "100%" }}
                      >
                        It seems you’ve entered your date of birth as a day within the last year. Please ensure that you enter your birthday correctly before proceeding.
                      </p>
                    </div>
                  }

                  <div >
                    <div className="patient_form_input" style={{
                      maxWidth: '35rem'
                    }}>
                      <label>Please select your ethnicity:</label>
                      <select
                        style={{ marginRight: "1rem" }}
                        type="text"
                        placeholder="Ethnicity"
                        id="ethnicity"
                        name="ethnicity"
                        value={formData.ethnicity}
                        onChange={(event) => {
                          setformData({ ...formData, ethnicity: event.target.value });
                          setformDataError("");
                        }}
                      >
                        <option value="" selected>
                          Select Ethnicity
                        </option>
                        <option value="Asian or Pacific Islander"> Asian or Pacific Islander </option>
                        <option value="Black or African American"> Black or African American </option>
                        <option value="Hispanic or Latino"> Hispanic or Latino </option>
                        <option value="Native American or Alaskan Native"> Native American or Alaskan Native </option>
                        <option value="White or Caucasian"> White or Caucasian </option>
                        <option value="Multiracial or Biracial"> Multiracial or Biracial </option>
                        <option value="A race/ethnicity not listed here"> A race/ethnicity not listed here </option>
                      </select>
                    </div>
                    <p className="error_message" >
                      {formDataError.ethnicity}
                    </p>
                  </div>

                  <div className="assessmnet__buttons" style={{ justifyContent: 'center' }}>
                    <div className="flex">
                      <button
                        className="assessmnet__button assessmnet__button--right"
                        id='nextupdateBtn'
                        onClick={onFormSubmitHandler}
                        disabled={birthDateError}
                      >
                        <span className="assessmnet__button--text">Next</span>
                        <div className="assessmnet__button--iconBox">
                          <svg className="assessmnet__button--icon">
                            <use xlinkHref="/sprite.svg#icon-next" />
                          </svg>
                        </div>
                      </button>
                    </div>
                  </div>
                </form>

              </div>
            </div>
            :
            <React.Fragment>
              <Assessment
                data={{
                  allQuestionData,
                  setAllQuestionData,
                  onNextQuestion,
                  onPreviousQuestion,
                  multipleAnswer,
                  setMultipleAnswer,
                  yesToggle,
                  NoToggle,
                  setYesToggle,
                  setNoToggle,
                  onReportDTO,
                  setNoInputError,
                  noInputError,
                  onFinalSubmit,
                  setBmiDemographic,
                  bmiDemographic,
                  pounds,
                  setPounds,
                  feet,
                  setFeet,
                  inches,
                  setInches,
                  selected,
                  setSelected,
                  singleAnswer,
                  setSingleAnswer,
                  setBenzQuestion,
                  benzQuestion,
                  userDetails,
                  phoneNumber,
                  setPhoneNumber,
                  iscellincluded,
                  setIsCellIncluded,
                  requestData,
                  setRequestData,
                  requestDataError,
                  setRequestDataError,
                  mapAreas,
                  setMapAreas
                }}
              />

              <div
                className="progressbarContainer"
                style={{ height: "1.5rem", marginBottom: "2rem" }}
              >
                <div
                  className="progressbar--completed"
                  style={{ width: `${allQuestionData.percentComplete}%` }}
                >
                  {
                    allQuestionData.percentComplete > 93 &&
                    <div className="progressbar-bar-inner-completed">{allQuestionData.percentComplete}% completed</div>
                  }
                </div>
                {
                  allQuestionData.percentComplete <= 93 &&
                  <div style={{
                    paddingLeft: '5px'
                  }}>{allQuestionData.percentComplete}% completed</div>
                }
                <div className="progressbar--notcompleted" />
              </div>
            </React.Fragment>
        }

        {/* <div>
					<ChatBox />
				</div> */}


        <ConsentFormModal
          modalData={consentFormModal}
          consentForm={consentForm.data}
          handleCancel={() => {
            setConsentFormModal({ status: false, step: 1 })
          }}
          handleSubmit={consentStepHandler}
        />

      </div>
    </Content>
  );
};

export default Questionare;
