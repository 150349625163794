
//production creds 
export const BASE_URL_LOGIN = 'https://api.suremedcompliance.com:5014/api'; 
export const STRIPE_PUBLISHABLE_KEY='pk_live_w8QXB4SL2fGPv4s5I31MeFDT';  // pk_live_key
export const promo_code='promo_1Mg55CGr9VjQZmKKUlGqOpJW';  // promo_live_key 
export const AdClinIQAPIUrl='https://cliniq.the-algo.com/';  // ads_prod_url



//staging creds
//export const BASE_URL_LOGIN = 'https://localhost:44322/api'; 
// export const BASE_URL_LOGIN = 'http://44.234.110.14/api';
// export const AdClinIQAPIUrl='http://72.167.37.148:8181/';  // ads_test_url
// export const STRIPE_PUBLISHABLE_KEY='pk_test_OzQ0kMcy2P5BIUXTpgqVuLjO'; // pk_test_key
// export const promo_code='promo_1Mg51BGr9VjQZmKKs7vm7uzn'; // promo_test_key

export let BASE_URL;
export const setUrl = (url) => {
	BASE_URL = url;
};
